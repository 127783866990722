import React from "react";
import { NotificationTypeType } from "../../../../utilities/redux/types";
import { FaBell, FaFileInvoiceDollar, FaKey, FaQuestion, FaUsers } from "../../../react-icons/fa";
import { SiSepa } from "../../../react-icons/si";

import { GiInjustice } from "../../../react-icons/gi";
import { MdReportProblem } from "../../../react-icons/md";
import { HiMiniBuildingLibrary } from "../../../react-icons/hi2";
import { IoIosAlarm } from "../../../react-icons/io";

type Props = {
  size?: number;
  color?: string;
  notificationType: NotificationTypeType;
};

export function NotificationTypeIcon({ notificationType, size = 17, color = "var(--color-text)" }: Props) {
  return (
    <>
      {!notificationType?.value && <FaBell size={size} color={color} />}
      {notificationType?.value === "billing" && <FaFileInvoiceDollar size={size} color={color} />}
      {notificationType?.value === "reminder" && <IoIosAlarm size={size} color={color} />}
      {notificationType?.value === "stripe" && <SiSepa size={size} color={color} />}
      {notificationType?.value === "pes" && <FaQuestion size={size} color={color} />}
      {notificationType?.value === "legal" && <GiInjustice size={size} color={color} />}
      {notificationType?.value === "auth" && <FaKey size={size} color={color} />}
      {notificationType?.value === "access" && <HiMiniBuildingLibrary size={size} color={color} />}
      {notificationType?.value === "report" && <MdReportProblem size={size} color={color} />}
      {notificationType?.value === "userCollaboration" && <FaUsers size={size} color={color} />}
    </>
  );
}
