const SvgComponent = ({ size }) => (
  <svg width={size + "px"} xmlns="http://www.w3.org/2000/svg" id="Calque_1" data-name="Calque 1" viewBox="0 0 1058.6 355.3">
    <defs>
      <style>{".cls-1{fill:#4A4A4A}"}</style>
    </defs>
    <path
      d="M394 113.4h35.8l25.8 104.5 28.8-104.5h27.7l28.8 104.5 25.8-104.5h35.8l-41.1 149.2h-38.9l-24.3-88-24.3 88h-38.8L394 113.4ZM711.2 229.8v32.8h-93.8V113.4h92.7v32.8h-58.6v24.7h53.3v32.4h-53.3v26.4h59.7v.1ZM817.8 229.8v32.8h-87.4V113.4h34.1v116.4h53.3ZM884.9 205.3v57.3h-34.1v-57.3l-50.1-91.9h38.8l28.4 57.6 28.4-57.6h38.8l-50.2 91.9ZM1058.6 218.9c0 26.4-21.5 43.7-48.2 43.7h-62.7V113.4h58.4c26 0 47.1 16.8 47.1 42.6 0 12.4-4.9 22.2-13 29.2 11.2 7.1 18.4 18.6 18.4 33.7Zm-76.7-73.5v26.4h24.3c7.7 0 13-5.5 13-13.2s-5.1-13.2-13-13.2h-24.3Zm42.6 71c0-8.3-5.5-14.3-14.1-14.3h-28.6v28.6h28.6c8.6-.1 14.1-6 14.1-14.3Z"
      className="cls-1"
    />
    <path
      d="M319.5.3c-1.2-.2-2.5 0-3.5.5l-71.9 33.3-23 133.5-21.2-44c-1-2.1-3.1-3.4-5.3-3.4h-.1c-.9 0-1.8.2-2.5.6L103.8 162 82.8 6.5c-.1-.6-.2-1.2-.4-1.7-.6-2.4-2.6-4.1-5-4.5-.8-.2-1.7-.3-2.6-.3-.2 0-.5 0-.7.1h-.8L0 37.8l42.9 294.8 72-39.7c1.4-.7 2.5-1.8 3.4-3l.6-.9 32.1-63.4 46.7 105.9 76.7-40c.2-.1.4-.3.6-.4l.1-.1c.2-.2.4-.3.6-.5l.6-.6.1-.1c.2-.2.3-.5.5-.7v-.1c.1-.2.2-.5.3-.7v-.1c.1-.3.2-.5.2-.8v-.1l47-280c.6-3.4-1.6-6.5-4.9-7ZM52.2 313.8 13.1 44.6l58.5-30.1 35.5 269-54.9 30.3Zm151.2 1.1-45.5-103.2 36.4-71.9 69.2 143.8-60.1 31.3Z"
      style={{
        fill: "#59c684",
      }}
    />
  </svg>
);
export default SvgComponent;
