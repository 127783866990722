import Link from "next/link";
import React, { type JSX } from "react";

type Props = {
  children: JSX.Element | JSX.Element[];
  url?: string;
  cb?: () => void;
  cbLink: () => void;
};

export default function BurgerTile({ children, url, cb, cbLink }: Props) {
  if (url) {
    return (
      <Link className="m-0 h-100" href={url} onClick={cbLink}>
        <div className="tile gray-light m-0 h-100 column space-between pt-2">{children}</div>
      </Link>
    );
  } else {
    return (
      <div
        onClick={() => {
          cb && cb();
        }}
        className="tile gray-light m-0 h-100 column space-between pt-2"
      >
        {children}
      </div>
    );
  }
}
