import React from "react";
import { FaAndroid, FaApple } from "../../../react-icons/fa";
import { SiXiaomi } from "../../../react-icons/si";
import { Tooltip } from "../../infos";

type Props = {
  device: "Mac" | "Iphone" | "K" | "Xiaomi" | string;
  size?: number;
};

export function DeviceIcon({ device, size = 25 }: Props) {
  return (
    <Tooltip legend={device}>
      {device?.toLowerCase() === "Mac".toLowerCase() && <FaApple size={size} />}
      {device?.toLowerCase() === "Iphone".toLowerCase() && <FaApple size={size} />}
      {device?.toLowerCase() === "K".toLowerCase() && <FaAndroid size={size} />}
      {device?.toLowerCase() === "Xiaomi".toLowerCase() && <SiXiaomi size={size} />}
    </Tooltip>);
}
