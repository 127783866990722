"use client";
import classNames from "classnames";
import { HTMLAttributes } from "react";
import { GrClose } from "../../../react-icons/gr";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  cb?: (param: boolean) => void;
  size?: number | string;
};

export const CloseButton = ({ cb, size = 20, ...props }: Props) => {
  return (
    <button {...props}
      className={classNames("icon-button button--close", cb ? "clickable" : "disabled", props.className)}
      onClick={(e) => {
        e.stopPropagation();
        cb && cb(false);
      }}
      aria-label="Fermer"
    >
      <GrClose size={size} />
    </button >
  );
};
