import classNames from "classnames";
import { HTMLAttributes } from "react";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";
import { Tooltip } from "../../infos";
import { CircleLoader } from "../../loaders";
import { ClassnameTypeType } from "../button/Button";

export type formType = "square" | "circle";
interface Props extends HTMLAttributes<HTMLButtonElement> {
  icon: IconList | string;
  type?: ClassnameTypeType;
  backgroundColor?: CssVariablesType;
  iconColor?: CssVariablesType;
  cb?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  form?: formType;
  size?: number;
  tooltip?: string;
  tooltipPosition?: "bottom" | "left" | "top" | "right";
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
}

export function IconButton({
  icon,
  form = "square",
  cb,
  isLoading = false,
  size = 18,
  type = "gray-light",
  backgroundColor,
  iconColor,
  tooltip = "",
  tooltipPosition = "bottom",

  className,
  disabled = false,
  ...props
}: Props) {
  return (
    <button
      {...props}
      role={!cb ? "div" : "button"}
      aria-hidden={props["aria-hidden"] ?? cb === undefined}
      tabIndex={(props.tabIndex ?? cb != undefined) ? 0 : -1}
      aria-label={props["aria-label"] ?? tooltip}
      className={classNames("icon-button", type, form, className, { clickable: cb && !(disabled || isLoading) }, { disabled: !cb || disabled || isLoading })}
      onClick={
        cb && !isLoading && disabled == false
          ? (e) => {
              e.stopPropagation();
              e.preventDefault();
              cb(e);
            }
          : (e) => {
              e.stopPropagation();
              e.preventDefault();
            }
      }
      style={{
        borderRadius: form === "square" ? `calc(${size}px / 3)` : "50%",
        padding: form === "square" ? `calc(2 * ${size}px / 5)` : `calc(${size}px / 3)`,
        aspectRatio: 1,
        maxHeight: size + "20px",
        backgroundColor: backgroundColor && `var(--color-${backgroundColor}`,
        ...props.style,
      }}
    >
      <Tooltip legend={tooltip} position={tooltipPosition}>
        {isLoading && <CircleLoader strokeWidth={7} width={size + "px"} color={`var(--color-${iconColor})`} />}

        {icon && isLoading == false && <IconButtonIcons icon={icon} size={size} color={iconColor} />}
      </Tooltip>
    </button>
  );
}
