import React, { HTMLAttributes, useState } from "react";
import { IconButton } from "../../buttons";
import classNames from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  cb?: (e?: React.MouseEvent<HTMLElement>) => void;
  sizeIcon?: number;
  children: any | any[];
}

export function ModificationTileDesktop({ cb, sizeIcon = 20, children, ...props }: Props) {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      {...props}
      tabIndex={cb ? 0 : -1}
      className={classNames("modification tile", cb && "clickable", props.className)}
      onClick={cb && cb}
      onMouseEnter={() => cb && setIsHover(true)}
      onMouseLeave={() => cb && setIsHover(false)}
      aria-label="Modification"
    >
      {children}
      {cb && (
        <IconButton
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            padding: "10px",
          }}
          className={classNames(isHover ? "visible" : "hidden", "edit-icon")}
          type="gray-light"
          form="circle"
          cb={cb && cb}
          size={sizeIcon}
          icon="edit"
        />
      )}
    </div>
  );
}
