import React from "react";
import { HslColorPicker } from "react-colorful";

export function CategoryIconColorChoicer({ color, setColor }) {
  const defaultColor = { h: 319, s: 60, l: 49 };

  function colorToHsl(color: string) {
    if (color?.includes("hsl")) {
      const [h, s, l] = color
        ?.slice(color.indexOf("(") + 1, color.indexOf(")"))
        .split(",")
        .map((i) => parseInt(i));

      return { h, s, l };
    }

    return defaultColor;
  }

  function onChange(hsl) {
    const { h, s, l } = hsl;

    if (hsl) {
      const result = `hsl(${h}, ${s}%, ${l}%)`;
      setColor(result);
      return result;
    }
  }

  return (
    <HslColorPicker color={colorToHsl(color)} onChange={onChange} />
  );
}
