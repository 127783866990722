import React from "react";
import { NotificationTopicType } from "../../../../utilities/redux/types";

type Props = {
  topic: NotificationTopicType;
  size?: number;
};

export function NotificationTopicIcon({ topic, size = 60 }: Props) {
  return (
    <div className="badge" style={{ width: size + "px", height: size + "px", position: "relative" }}>
      <img src={"/icons/" + topic.icon} alt="" />
    </div>
  );
}
