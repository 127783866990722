import React, { HTMLAttributes } from "react";
import { IconButton } from "../../buttons";
import classNames from "classnames";

interface Props extends HTMLAttributes<HTMLDivElement> {
  cb?: (e?: React.MouseEvent<HTMLElement>) => void;
  sizeIcon?: number;
  children: any | any[];
};

export function ModificationTileMobile({ cb, sizeIcon = 20, children, ...props }: Props) {
  return (
    <div
      {...props}
      className={classNames("modification tile mobile", cb && "clickable", props.className)}
      style={{ position: "relative", ...props.style }}
      onClick={cb && cb}
    >
      {children}
      {cb &&
        <IconButton
          style={{
            position: "absolute",
            right: "10px",
            top: "10px",
            padding: "10px",
          }}
          className="edit-icon"
          type="gray-light"
          form="circle"
          size={sizeIcon}
          icon="edit"
          cb={cb && cb} />
      }
    </div>
  );
}
