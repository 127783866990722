import React from "react";
import { IconButton } from "../iconButton/IconButton";
import { Button, ClassnameTypeType } from "../button/Button";
import { IconList } from "../../icons";

type Props = {
  url: string;
  filename: string;
  fetcher: any;
  size?: number;
  type?: "iconButton" | "button";
  icon?: IconList;
  color?: ClassnameTypeType;
  legend?: string;
};

export function DownloadButton({
  fetcher,
  url,
  filename,
  size = 15,
  type = "iconButton",
  icon = "download",
  color = "transparent",
  legend = "Télécharger"
}: Props) {
  function downloadEntryImport() {
    fetcher && fetcher.get(url, { responseType: "blob" }).then((resp: any) => {
      if (resp?.status === 200) {
        const href = URL.createObjectURL(resp.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
    });
  }

  return (
    <>
      {type === "iconButton"
        && <IconButton
          type={color}
          disabled={!fetcher}
          size={size}
          icon={icon}
          cb={downloadEntryImport}
          tooltip={legend}
          tooltipPosition="bottom"
        />
      }

      {type === "button" && (
        <Button disabled={!fetcher} type={color} size="small" iconSize={size} icon={icon} cb={downloadEntryImport} className="mt-0 mb-0 w-100">
          {legend}
        </Button >
      )
      }
    </>
  );
}
