import classNames from "classnames";
import { CategoryIcon } from "..";
import { CategoryIconType } from "../../../../utilities/redux/types";

interface Props {
  data: CategoryIconType;
  color?: string;
  setter: (param: CategoryIconType) => void;
}

export function CategoryIconChoicer({ data, setter, color = "var(--color-gray16)" }: Props) {

  const iconList = [
    "FaMoneyBillWaveAlt",
    "FaUserFriends",
    "FaTruck",
    "FaBalanceScale",
    "FaClock",
    "IoReceipt",
    "FaBoxOpen",
    "TbMoneybag",
    "FaAddressBook",
    "FaBuildingColumns",
    "FaEarthEurope",
    "FaArchive",
    "FaBed",
    "FaBroom",
  ];

  function onClick(icon: CategoryIconType) {
    setter(icon);
  }

  return (
    <div className="category-choicer">
      {iconList.map((icon: CategoryIconType) => (
        <CategoryIcon
          key={"icon_" + icon}
          className={classNames("icon clickable", icon === data && "selected")}
          iconName={icon}
          size={18}
          onClick={() => onClick(icon)}
          color={color}
        />
      ))}
    </div>
  );
}
