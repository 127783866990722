import React from "react";
import { BsFiletypeCsv, BsFiletypeDoc, BsFiletypeDocx, BsFiletypePdf } from "../../../react-icons/bs";
import { IoIosAttach } from "../../../react-icons/io";

type Props = {
  attachment: any,
  extAccepted?: string[],
  size?: number;
};

export function AttachmentIcon({
  attachment,
  extAccepted = [
    "image/bmp",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/msword",
    "image/jpeg",
    "image/bmp",
    "image/png",
    "application/pdf",
    "text/csv",
  ],
  size = 25,
}: Props) {
  return (
    <div className="preview m-auto">
      {(attachment?.type?.toLowerCase() === "image/jpeg" ||
        attachment?.type?.toLowerCase() === "image/bmp" ||
        attachment?.type?.toLowerCase() === "image/png")
        && <img src={URL.createObjectURL(attachment)} alt={attachment.type} />}

      {!extAccepted.includes(attachment?.type?.toLowerCase()) && <IoIosAttach size={size} />}

      {attachment?.type === "text/csv" && <BsFiletypeCsv size={size} />}
      {attachment?.type === "application/pdf" && <BsFiletypePdf size={size} />}

      {attachment?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && <BsFiletypeDocx size={size} />}
      {attachment?.type === "application/msword" && <BsFiletypeDoc size={size} />}
    </div>
  );
}
