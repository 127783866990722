import { Dispatch, SetStateAction } from "react";
import { IconButton } from "../iconButton/IconButton";

interface Props {
  setMenuActive: Dispatch<SetStateAction<boolean>>;
}

export function MenuBurgerButton({ setMenuActive }: Props) {
  return (
    <div id="menuBurger-btn">
      <IconButton
        icon="menu"
        form="circle"
        cb={() => {
          setMenuActive((prev) => !prev);
        }}
        size={33}
      />
    </div>
  );
}
