import React from "react";
import { NotificationType } from "../../../../utilities/redux/types";
import { IoMail } from "../../../react-icons/io5";
import { HiMiniBellAlert } from "../../../react-icons/hi2";
import { Shape } from "../../shapes";
import { Tooltip } from "../../infos";

type Props = {
  notification: NotificationType;
  size?: number;
};

export function NotificationCommunicationType({ notification, size = 23 }: Props) {
  return (
    <div className="row icons-communicationtype gap-1">
      {notification?.isMailSent == true && (
        <Tooltip legend={"Envoyé par e-mail"}>
          <Shape color="gray2" size={size}>
            <IoMail color="var(--color-gray11)" size={size / 1.8} />
          </Shape>
        </Tooltip>
      )}
      {notification?.isPushSent == true && (
        <Tooltip legend={"Notification push"} position="top">
          <Shape color="gray2" size={size}>
            <HiMiniBellAlert color="var(--color-gray11)" size={size / 1.8} />
          </Shape>
        </Tooltip>
      )}
    </div>
  );
}
