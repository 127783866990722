"use client";

import React, { useEffect } from "react";

import { BsFillMoonFill, BsFillSunFill } from "../../../react-icons/bs";
import { UserType } from "../../../../utilities/redux/types";
import { usePathname } from "next/navigation";

type Props = {
  user: UserType;
  darkmode: boolean;
  activeDarkMode: () => void;
  disactiveDarkMode: () => void;
};

export function DarkModeBtn({ darkmode, activeDarkMode, disactiveDarkMode, user }: Props) {
  const pathName = usePathname();

  return (
    <>
      {pathName && !pathName.includes("/dashboard") && !user?.email && (
        <>
          {darkmode ? (
            <button aria-label="dark mode" id="darkmode-btn" onClick={disactiveDarkMode}>
              <BsFillSunFill size={20} color="var(--color-text)" />
            </button>
          ) : (
            <button aria-label="dark mode" id="darkmode-btn" onClick={activeDarkMode}>
              <BsFillMoonFill size={20} color="var(--color-text)" />
            </button>
          )}
        </>
      )}
    </>
  );
}
