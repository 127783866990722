"use client";

import { HTMLAttributes } from "react";
import { useMediaQuery } from "../../../../utilities/hooks/useMediaQuery";
import { ModificationTileDesktop } from "./ModificationTileDesktop";
import { ModificationTileMobile } from "./ModificationTileMobile";

interface Props extends HTMLAttributes<HTMLDivElement> {
  cb?: (e?: React.MouseEvent<HTMLElement>) => void;
  sizeIcon?: number;
  children: any | any[];
};

export function ModificationTile({ cb, sizeIcon = 20, children, ...props }: Props) {
  const mediaQuery = useMediaQuery("920");

  return (
    <>
      {mediaQuery && (
        <ModificationTileMobile {...props} cb={cb} sizeIcon={sizeIcon} >
          {children}
        </ModificationTileMobile>
      )}
      {!mediaQuery && (
        <ModificationTileDesktop {...props} cb={cb} sizeIcon={sizeIcon} >
          {children}
        </ModificationTileDesktop>
      )}
    </>
  );
}
