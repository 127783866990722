"use client";

import classNames from "classnames";
import { HTMLAttributes } from "react";
import { CssVariablesType } from "../../../styles/CSSVariablesTypes";
import { IconButtonIcons, IconList } from "../../icons";
import { CircleLoader } from "../../loaders";

export type ClassnameTypeType =
  | "primary"
  | "primary-light"
  | "white"
  | "transparent"
  | "dark"
  | "error"
  | "red"
  | "error-light"
  | "red-light"
  | "secondary-light"
  | "gray"
  | "gray-light"
  | "warning"
  | "yellow"
  | "warning-light"
  | "yellow-light"
  | "success"
  | "green"
  | "success-light"
  | "green-light"
  | "info"
  | "blue"
  | "info-light"
  | "blue-light"
  | "empty";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children: any | any[];
  cb?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  type?: ClassnameTypeType;
  id?: string;
  className?: string;
  form?: "square" | "circle";
  size?: "big" | "small" | "medium";
  iconSize?: number;
  backgroundColor?: CssVariablesType;
  iconColor?: CssVariablesType;
  icon?: IconList | JSX.Element;
  isLoading?: boolean;
  classNameIcon?: string;
}

export const Button = ({
  children = "Button",
  cb,
  isLoading = false,
  disabled = false,
  type = "primary",
  id,
  className,
  size = "medium",
  form = "square",
  iconSize = 20,
  backgroundColor,
  iconColor,
  icon,
  classNameIcon = "",
  ...props
}: Props) => {
  return (
    <button
      {...props}
      type="button"
      className={classNames("button", type, className, size, form, {
        clickable: cb && isLoading == false && disabled === false,
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        cb && cb(e);
      }}
      disabled={disabled || !cb || isLoading}
      id={id ? id : undefined}
      style={{
        backgroundColor: backgroundColor && `var(--color-${backgroundColor}`,
        ...props.style,
      }}
    >
      {icon !== "next" && isLoading && <CircleLoader strokeWidth={7} width={iconSize + "px"} color={iconColor && `var(--color-${iconColor})`} />}
      {icon && typeof icon === "string" && icon !== "next" && isLoading == false && <IconButtonIcons color={iconColor} icon={icon} size={iconSize} className={classNameIcon} />}
      {icon && typeof icon !== "string" && isLoading == false && icon}

      <div className="legend">{children}</div>

      {icon && icon === "next" && isLoading == false && <IconButtonIcons color={iconColor} icon={icon} size={iconSize} className={classNameIcon} />}
      {icon && icon === "next" && isLoading && <CircleLoader strokeWidth={7} width={iconSize + "px"} color={iconColor && `var(--color-${iconColor})`} />}
    </button>
  );
};
