import { GrConnect } from "react-icons/gr";
import { AccountingFirmConnectorTypeType } from "../../../../utilities/redux/types";
import { BsFileArrowDownFill } from "../../../react-icons/bs";
import { Tooltip } from "../../infos";
import Dext from "../svg/Dext";
import MyUnisoft from "../svg/MyUnisoft";
import PennyLane from "../svg/PennyLane";
import Acd from "./../svg/Acd";
import Cegid from "./../svg/Cegid";
import Sage from "./../svg/Sage";
import Tiime from "./../svg/Tiime";
import Welyb from "./../svg/Welyb";

const Icon = ({ value, size }) => {
  if (value == "fec") return <BsFileArrowDownFill className={"icon"} size={size} color="var(--color-main)" />;
  if (value == "quadra") return <Cegid size={size} />;
  if (value == "acd") return <Acd size={size} />;
  if (value == "tiime") return <Tiime size={size} />;
  if (value == "myunisoft") return <MyUnisoft size={size} />;
  if (value == "pennylane") return <PennyLane size={size} />;
  if (value == "dext") return <Dext size={size} />;
  if (value == "welyb") return <Welyb size={size + 30} />;
  if (value == "sage") return <Sage size={size} />;
  return <GrConnect className={"icon"} size={size} />;
};

export function AccountingFirmConnectorIcon({
  item,
  size = 40,
  tooltip = false,
}: {
  item: AccountingFirmConnectorTypeType | null;
  size?: number;
  className?: string;
  color?: string;
  tooltip?: boolean;
}) {
  return (
    <>
      <Tooltip legend={tooltip && (item?.name || item?.value || "")} position="bottom">
        {item?.value ? <Icon value={item?.value} size={size} /> : <GrConnect size={size} />}
      </Tooltip>
    </>
  );
}
