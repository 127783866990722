"use client";
import { ModalDesktop } from "./ModalDesktop";
import { HTMLAttributes, useEffect, useRef, type JSX } from "react";

interface Props extends HTMLAttributes<HTMLDialogElement> {
  setShowModal?: (arg: boolean) => void;
  showModal: boolean;
  id?: string;
  className?: string;
  width?: string;
  height?: string;
  translateX?: string;
  translateY?: string;
  maxWidth?: number;
  maxHeight?: number;
};

export function Modal({
  children,
  id,
  setShowModal,
  showModal,
  width,
  height = "fit-content",
  translateX,
  translateY,
  className,
  maxWidth,
  maxHeight,
  ...props
}: Props): JSX.Element {
  const ref = useRef(null);

  useEffect(() => {
    if (showModal) {
      ref?.current?.showModal();
    } else {
      ref?.current?.close();
    }
  }, [showModal]);

  return (
    <>
      {showModal &&
        <ModalDesktop
          id={id}
          setShowModal={setShowModal}
          width={width}
          height={height}
          translateX={translateX}
          translateY={translateY}
          className={className}
          maxWidth={maxWidth}
          maxHeight={maxHeight}
          ref={ref}
          {...props}
        >
          {children}
        </ModalDesktop>}
    </>
  );
}
