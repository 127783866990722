import { AiFillStar } from "../../../react-icons/ai";
import { CompanyPermissionType } from "../../../../utilities/redux/types";
import classNames from "classnames";
import { FaShield } from "../../../react-icons/fa6";

type Props = {
  companyPermission: CompanyPermissionType;
  size?: number;
};

export function UserCompanyPermissionIcon({ companyPermission, size = 30 }: Props) {

  if (companyPermission?.isAdmin || companyPermission?.isOwner) {
    return (
      <div className={classNames("company-permission icon", `w-${size}`, companyPermission?.isAdmin ? "admin" : "owner")}>
        {companyPermission?.isAdmin && <FaShield />}

        {!companyPermission?.isAdmin && companyPermission?.isOwner && <AiFillStar />}
      </div>
    );
  } else return <></>;
}