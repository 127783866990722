import { CiBarcode } from "../../../react-icons/ci";
import { FaDatabase, FaMoneyBill, FaTrashAlt } from "../../../react-icons/fa";
import { AiFillAlert } from "../../../react-icons/ai";
import { IoIosStats } from "../../../react-icons/io";

type Props = {
  value: "cleaning" | "alert" | "billing" | "statistic" | "table" | "table_code";
};

export function CronCategoryIcon({ value }: Props) {
  return (
    <>
      {value === "cleaning" && <FaTrashAlt />}
      {value === "alert" && <AiFillAlert />}
      {value === "billing" && <FaMoneyBill />}
      {value === "statistic" && <IoIosStats />}
      {value === "table" && <FaDatabase />}
      {value === "table_code" && <CiBarcode />}
    </>
  );
}
