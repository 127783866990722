import React from "react";
import { SiGooglechrome, SiSamsung, SiXiaomi } from "../../../react-icons/si";
import { Tooltip } from "../../infos";
import { FaChrome, FaEdgeLegacy, FaFirefox, FaOpera, FaSafari } from "../../../react-icons/fa";

type Props = {
  browser: "Samsung" | "Safari" | "Opera" | "Miui" | "Google" | "Firefox" | "Edge" | "Chrome" | string;
  size?: number;
};

export function BrowserIcon({ browser, size = 25 }: Props) {
  return (
    <Tooltip legend={browser}>
      {browser?.toLowerCase().includes("Samsung".toLowerCase()) && <SiSamsung size={size} />}

      {browser?.toLowerCase().includes("Safari".toLowerCase()) && <FaSafari size={size} />}

      {browser?.toLowerCase().includes("Opera".toLowerCase()) && <FaOpera size={size} />}

      {browser?.toLowerCase().includes("Miui".toLowerCase()) && <SiXiaomi size={size} />}

      {browser?.toLowerCase().includes("Google".toLowerCase()) && <SiGooglechrome size={size} />}

      {browser?.toLowerCase().includes("Firefox".toLowerCase()) && <FaFirefox size={size} />}

      {browser?.toLowerCase().includes("Edge".toLowerCase()) && <FaEdgeLegacy size={size} />}

      {browser?.toLowerCase().includes("Chrome".toLowerCase()) && <FaChrome size={size} />}
    </Tooltip>
  );
}
