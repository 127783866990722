import React from "react";
import { Grid } from "../grid/Grid";

export default function BurgerGrid({ children }) {
  return (
    <Grid template="repeat(auto-fit, minmax(160px, 1fr))" mediaQuery={0} space={8}>
      {children}
    </Grid>
  );
}
