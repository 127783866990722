type Props = {
  entryCount: number;
};

export function CompanySizeIcon({ entryCount }: Props) {
  return (
    <div
      className="row a-center"
      style={{
        background: `rgba(0, 205, 172, ${entryCount / 15000})`,
        width: "100%",
        height: "100%",
        borderRadius: "3px",
        textAlign: "center",
        justifyContent: "center",
        fontWeight: 700,
        fontSize: "0.7rem",
        color: entryCount > 5000 ? `rgba(255, 255, 255, 1)` : `rgba(0, 205, 172, 1)`,
        border: "1px solid rgb(0, 205, 172)",
      }}
    >
      {entryCount <= 100 && "XS"}
      {entryCount > 100 && entryCount <= 500 && "S"}
      {entryCount > 500 && entryCount <= 5000 && "M"}
      {entryCount > 5000 && entryCount <= 20000 && "L"}
      {entryCount > 20000 && "XL"}
    </div>
  );
}
