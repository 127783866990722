import { useRouter } from "next/navigation";
import { Grid } from "../../../components/blocs";
import React from "react";
import { FaPowerOff } from "../../../react-icons/fa";

export default function MenuBurgerDeconnexionButton() {
  const router = useRouter();
  return (
    <Grid
      template="auto 1fr"
      alignItems="center"
      mediaQuery={0}
      className="tile gray-light w-100 pt-3 pb-3 br-5 pl-3 pr-3 mt-4"
      onClick={() => router.push("/auth/deconnexion")}
    >
      <FaPowerOff size={30} color={"var(--color-error)"} />
      <h3 className="m-0">Déconnexion</h3>
    </Grid>
  );
}
