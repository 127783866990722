import React from "react";
import { BiSupport } from "../../../react-icons/bi";
import { BsFillBuildingFill, BsFillGearFill } from "../../../react-icons/bs";
import { FaStar, FaUser, } from "../../../react-icons/fa";
import { HiBuildingLibrary, HiOutlineBuildingLibrary } from "../../../react-icons/hi2";
import { MdOutlineWork } from "../../../react-icons/md";
import { RoleTypeType } from "../../../../utilities/redux/types";

type Props = {
  role: RoleTypeType;
  size?: number;
  color?: string;
};

export function RoleIcon({
  role,
  size = 30,
  color = "var(--color-text)",
}: Props) {
  return (
    <>
      {role === "ROLE_USER" && <FaUser size={size} color={color} />}
      {role === "ROLE_COMPANY" && (
        <BsFillBuildingFill size={size} color={color} />
      )}
      {role === "ROLE_EXPERT" && (
        <HiOutlineBuildingLibrary size={size} color={color} />
      )}
      {role === "ROLE_EXPERT_OWNER" && (
        <HiBuildingLibrary size={size} color={color} />
      )}
      {role === "ROLE_ADMIN" && <BsFillGearFill size={size} color={color} />}
      {role === "ROLE_SUPER_ADMIN" && <FaStar size={size} color={color} />}
      {role === "ROLE_COMMERCIAL" && (
        <MdOutlineWork size={size} color={color} />
      )}
      {role === "ROLE_SUPPORT" && (
        <BiSupport size={size} color={color} />
      )}
    </>
  );
}
