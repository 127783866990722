"use client";

import { HTMLAttributes, forwardRef } from "react";
import { CloseButton } from "../buttons";
import { motion } from "framer-motion";
import classNames from "classnames";

interface Props extends HTMLAttributes<HTMLDialogElement> {
  setShowModal?: (arg: boolean) => void;
  id?: string;
  className?: string;
  width?: string;
  height?: string;
  translateX?: string;
  translateY?: string;
  maxWidth?: number;
  maxHeight?: number;
}

export const ModalDesktop = forwardRef(function ModalDesktop(
  { children, id, setShowModal, width = "80vw", height, translateX = "0", translateY = "0", maxWidth = 900, maxHeight = null, className, ...props }: Props,
  ref: any
) {
  const style = {
    width: width,
    height: height,
    maxWidth: maxWidth + "px",
    maxHeight: maxHeight ? maxHeight + "px" : "90vh",
    translate: `${translateX} ${translateY}`,
    ...props.style,
  };

  const contentVariants = {
    visible: { y: 0, opacity: 1, scale: 1 },
    hidden: { y: 500, opacity: 0 },
  };

  return (
    <motion.dialog
      ref={ref}
      className={classNames(className, "modal")}
      id={id ? id : undefined}
      key={id ?? "modal"}
      onClick={(e) => e.stopPropagation()}
      style={style}
      open={false}
      onCancel={(e) => {
        if (setShowModal) setShowModal(false);
        else { e.preventDefault(); }
      }}
      onMouseDown={(e) => {
        e.target == ref?.current && setShowModal && setShowModal(false);
      }}
      variants={contentVariants}
    >
      {setShowModal && <CloseButton className="header p-1" cb={() => setShowModal(false)} />}
      <div className="body" style={{ height: "fit-content", width: "100%", minHeight: "100%" }}>
        {children}
      </div>
    </motion.dialog >
  );
});
