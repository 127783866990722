import React from "react";
import { BsQuestionLg } from "../../../react-icons/bs";
import { HiUpload } from "../../../react-icons/hi";
import { RiSendPlaneFill } from "../../../react-icons/ri";

type Props = {
  icon: "uploaded" | "question_generated" | "question_sended";
  size?: number;
};

export function EntryImportStateIcon({ icon, size = 30 }: Props) {
  return (
    <>
      {icon === "uploaded" && <HiUpload size={size} />}
      {icon === "question_generated" && <BsQuestionLg size={size} />}
      {icon === "question_sended" && <RiSendPlaneFill size={size} />}
    </>
  );
}
