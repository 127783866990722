import { useMediaQuery } from "../../../../utilities/hooks/useMediaQuery";
import BtnContainerDesktop from "./BtnContainerDesktop";
import BtnContainerMobile from "./BtnContainerMobile";

type Props = {
  children: any[] | any;
  position?: "left" | "center" | "right" | "auto";
  espacement?: number;
  className?: string;
  forceChildrenLength?: number;
  mediaQuery?: number;
};

export function BtnContainer({
  children,
  position,
  espacement = 7,
  className = "mt-1",
  forceChildrenLength,
  mediaQuery = 500,
}: Props) {
  const query = useMediaQuery(mediaQuery);

  const newChildren = Array.isArray(children) ? children.filter(element => element) : children;

  return (
    <>
      {query
        ? <BtnContainerMobile position={position} espacement={espacement} className={className}>
          {newChildren}
        </BtnContainerMobile>
        : <BtnContainerDesktop position={position} className={className} espacement={espacement} forceChildrenLength={forceChildrenLength}>
          {newChildren}
        </BtnContainerDesktop>
      }
    </>
  );
}
