import React from "react";
import { FaFileContract, FaFileInvoiceDollar, FaMoneyBill, FaMoneyBillAlt, FaPlug, FaUserFriends } from "../../../react-icons/fa";
import { IoMail, IoSettingsSharp } from "../../../react-icons/io5";
import { MdAddCircle, MdMoneyOffCsred, MdOutlinePassword, MdOutlinePayment, MdQuestionMark } from "../../../react-icons/md";
import { PiTestTubeFill } from "../../../react-icons/pi";
import { SiAdblock } from "../../../react-icons/si";
import { TbDiscount2, TbPower } from "../../../react-icons/tb";
import { ReportTypeIcon } from "..";
import { RiUserSettingsLine } from "../../../react-icons/ri";
import { BsBell, BsFileArrowDownFill, BsFillBuildingsFill, BsPlugFill } from "../../../react-icons/bs";
import { VscSettings } from "../../../react-icons/vsc";
import { HiBuildingOffice, HiIdentification } from "../../../react-icons/hi2";
import { AiFillTag } from "../../../react-icons/ai";
import { GrUpdate } from "../../../react-icons/gr";
import { IoIosSend } from "../../../react-icons/io";
import { LuAlarmClock } from "../../../react-icons/lu";
import Cegid from "../svg/Cegid";
import Tiime from "../svg/Tiime";
import Acd from "../svg/Acd";
import { FaMoneyBillTransfer } from "../../../react-icons/fa6";

type Type = "demo" | "blocked_accountingFirm" | "password" | "connector" | "tarification" | "payment_process" | "discount" | "contract" | "mail" | "connexion" | "user_preference" | "user_notification" | "preference" | "users" | "company_create" | "company_informations" | "company_employees" | "categories" | "company" | "accountingFirm_informations" | "accountingFirm_employees" | "suggest" | "accountingFirm" | "importation" | "cycle" | "send" | "relance" | "PES" | "fec" | "quadra" | "tiime" | "acd" | "connectors" | "technique" | "tarification" | "payment" | "credit" | "demo" | "commercial";

type Props = {
  type: Type;
  size?: number;
  color?: string;
  typeSelected?: "technical" | "commercial" | "suggestion" | "message";
};

export function ReportTopicIcon({ type, size = 25, color = "var(--color-text)", typeSelected = null }: Props) {
  return (
    <>
      {/* Default value */}
      {type == null && <ReportTypeIcon type={typeSelected} />}

      {/* Other values */}
      {type === "demo" && <PiTestTubeFill size={size} color={color} />}
      {type === "blocked_accountingFirm" && <SiAdblock size={size} color={color} />}
      {type === "password" && <MdOutlinePassword size={size} color={color} />}
      {type === "connector" && <FaPlug size={size} color={color} />}
      {type === "tarification" && <FaMoneyBill size={size} color={color} />}
      {type === "payment_process" && <MdOutlinePayment size={size} color={color} />}
      {type === "discount" && <TbDiscount2 size={size} color={color} />}
      {type === "contract" && <FaFileContract size={size} color={color} />}
      {type === "mail" && <IoMail size={size} color={color} />}
      {type === "connexion" && <TbPower size={size} color={color} />}
      {type === "user_preference" && <RiUserSettingsLine size={size} color={color} />}
      {type === "user_notification" && <BsBell size={size} color={color} />}
      {type === "preference" && <VscSettings size={size} color={color} />}
      {type === "users" && <FaUserFriends size={size} color={color} />}
      {type === "company_create" && <MdAddCircle size={size} color={color} />}
      {type === "company_informations" && <HiIdentification size={size} color={color} />}
      {type === "company_employees" && <FaUserFriends size={size} color={color} />}
      {type === "categories" && <AiFillTag size={size} color={color} />}
      {type === "company" && <BsFillBuildingsFill size={size} color={color} />}
      {type === "accountingFirm_informations" && <HiIdentification size={size} color={color} />}
      {type === "accountingFirm_employees" && <FaUserFriends size={size} color={color} />}
      {type === "suggest" && <AiFillTag size={size} color={color} />}
      {type === "accountingFirm" && <HiBuildingOffice size={size} color={color} />}
      {type === "importation" && <BsFileArrowDownFill size={size} color={color} />}
      {type === "cycle" && <GrUpdate size={size} color={color} />}
      {type === "send" && <IoIosSend size={size} color={color} />}
      {type === "relance" && <LuAlarmClock size={size} color={color} />}
      {type === "PES" && <MdQuestionMark size={size} color={color} />}
      {type === "fec" && <BsFileArrowDownFill size={size} color={color} />}
      {type === "quadra" && <Cegid size={size} />}
      {type === "tiime" && <Tiime size={size} />}
      {type === "acd" && <Acd size={size} />}
      {type === "connectors" && <BsPlugFill size={size} color={color} />}
      {type === "technique" && <IoSettingsSharp size={size} color={color} />}
      {type === "tarification" && <FaMoneyBillAlt size={size} color={color} />}
      {type === "payment" && <FaFileInvoiceDollar size={size} color={color} />}
      {type === "credit" && <FaFileInvoiceDollar size={size} color={color} />}
      {type === "demo" && <MdMoneyOffCsred size={size} color={color} />}
      {type === "commercial" && <FaMoneyBillTransfer size={size} color={color} />}
    </>
  );
}
