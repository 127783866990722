import { GenIcon } from '../lib';

export function TiThSmall (props) {
  return GenIcon({"tag":"svg","attr":{"version":"1.2","baseProfile":"tiny","viewBox":"0 0 24 24"},"child":[{"tag":"circle","attr":{"cx":"5","cy":"19","r":"2.5"}},{"tag":"circle","attr":{"cx":"5","cy":"12","r":"2.5"}},{"tag":"circle","attr":{"cx":"5","cy":"5","r":"2.5"}},{"tag":"circle","attr":{"cx":"12","cy":"19","r":"2.5"}},{"tag":"circle","attr":{"cx":"12","cy":"12","r":"2.5"}},{"tag":"circle","attr":{"cx":"12","cy":"5","r":"2.5"}},{"tag":"circle","attr":{"cx":"19","cy":"19","r":"2.5"}},{"tag":"circle","attr":{"cx":"19","cy":"12","r":"2.5"}},{"tag":"circle","attr":{"cx":"19","cy":"5","r":"2.5"}}]})(props);
};
export function TiArrowBack (props) {
  return GenIcon({"tag":"svg","attr":{"version":"1.2","baseProfile":"tiny","viewBox":"0 0 24 24"},"child":[{"tag":"path","attr":{"d":"M12 9.059v-2.559c0-.256-.098-.512-.293-.708-.195-.195-.451-.292-.707-.292s-.512.097-.707.292l-6.293 6.208 6.293 6.207c.195.195.451.293.707.293s.512-.098.707-.293.293-.452.293-.707v-2.489c2.75.068 5.755.566 8 3.989v-1c0-4.633-3.5-8.443-8-8.941z"}}]})(props);
};
export function TiDelete (props) {
  return GenIcon({"tag":"svg","attr":{"version":"1.2","baseProfile":"tiny","viewBox":"0 0 24 24"},"child":[{"tag":"path","attr":{"d":"M12 4c-4.419 0-8 3.582-8 8s3.581 8 8 8 8-3.582 8-8-3.581-8-8-8zm3.707 10.293c.391.391.391 1.023 0 1.414-.195.195-.451.293-.707.293s-.512-.098-.707-.293l-2.293-2.293-2.293 2.293c-.195.195-.451.293-.707.293s-.512-.098-.707-.293c-.391-.391-.391-1.023 0-1.414l2.293-2.293-2.293-2.293c-.391-.391-.391-1.023 0-1.414s1.023-.391 1.414 0l2.293 2.293 2.293-2.293c.391-.391 1.023-.391 1.414 0s.391 1.023 0 1.414l-2.293 2.293 2.293 2.293z"}}]})(props);
};
export function TiSortAlphabetically (props) {
  return GenIcon({"tag":"svg","attr":{"version":"1.2","baseProfile":"tiny","viewBox":"0 0 24 24"},"child":[{"tag":"path","attr":{"d":"M10.895 16.553l-4-8c-.339-.678-1.45-.678-1.789 0l-4 8c-.247.494-.047 1.095.447 1.342.495.248 1.095.046 1.342-.447l.723-1.448h4.764l.724 1.447c.175.351.528.553.895.553.15 0 .303-.034.446-.105.494-.248.695-.848.448-1.342zm-6.277-2.553l1.382-2.764 1.382 2.764h-2.764zM22 18h-6c-.379 0-.725-.214-.895-.553s-.132-.744.095-1.047l4.8-6.4h-4c-.552 0-1-.448-1-1s.448-1 1-1h6c.379 0 .725.214.895.553s.132.744-.095 1.047l-4.8 6.4h4c.552 0 1 .448 1 1s-.448 1-1 1zM14 14h-2c-.552 0-1-.448-1-1s.448-1 1-1h2c.552 0 1 .448 1 1s-.448 1-1 1z"}}]})(props);
};
export function TiVendorAndroid (props) {
  return GenIcon({"tag":"svg","attr":{"version":"1.2","baseProfile":"tiny","viewBox":"0 0 24 24"},"child":[{"tag":"path","attr":{"d":"M7.1 8h9.799999999999999c.1 0 .1 0 .1-.1-.1-.5-.2-.9-.4-1.3-.4-.9-1.1-1.5-1.9-2l-.4-.2s0-.1.1-.1c.3-.4.6-.8.9-1.3.1-.1.1-.2 0-.3-.1-.1-.2 0-.3.1l-.9 1.3s-.1.1-.1 0c-.8-.3-1.6-.4-2.4-.4-.6 0-1.1.2-1.6.4h-.1l-.9-1.2s0-.1-.1-.1c-.1-.1-.2-.1-.2 0-.1 0-.1.1 0 .2 0 0 0 .1.1.1.2.4.5.8.8 1.2l.1.1h-.1c-.6.3-1.1.7-1.5 1.2-.6.6-1 1.4-1.1 2.3 0 .1 0 .1.1.1zm7.1-2.8c.4 0 .8.3.8.8 0 .4-.3.8-.7.8-.4 0-.8-.3-.8-.8-.1-.4.3-.8.7-.8zm-4.3 0c.4 0 .8.3.8.8 0 .4-.3.8-.7.8-.5 0-.9-.4-.9-.8s.4-.8.8-.8zM5 9c-.5 0-1 .5-1 1v5c0 .5.5 1 1 1s1-.5 1-1v-5c0-.5-.5-1-1-1zM19 9c-.5 0-1 .5-1 1v5c0 .5.5 1 1 1s1-.5 1-1v-5c0-.5-.5-1-1-1zM7 17c0 .5.5 1 1 1h1v3c0 .5.5 1 1 1s1-.5 1-1v-3h2v3c0 .5.5 1 1 1s1-.5 1-1v-3h1c.5 0 1-.5 1-1v-8h-10v8z"}}]})(props);
};