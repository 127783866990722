import React from "react";
import { FaBuilding, FaUserFriends } from "../../../react-icons/fa";
import { FaComputer } from "../../../react-icons/fa6";
import { IoMdSchool } from "../../../react-icons/io";
import { RiPaintFill } from "../../../react-icons/ri";
import { CiDatabase } from "../../../react-icons/ci";

type Props = {
  type: "formation" | "setup" | "user" | "company" | "whitemark" | "save" | "backup";
  size?: number;
  color?: string;
};

export function PricingNatureIcon({ type, size = 25, color = "var(--color-text)" }: Props) {
  return (
    <>
      {type === "formation" && <IoMdSchool size={size} color={color} />}
      {type === "setup" && <FaComputer size={size} color={color} />}
      {type === "user" && <FaUserFriends size={size} color={color} />}
      {type === "company" && <FaBuilding size={size} color={color} />}
      {type === "whitemark" && <RiPaintFill size={size} color={color} />}
      {type === "save" && <CiDatabase size={size} color={color} />}
    </>
  );
}
