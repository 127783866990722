import classNames from "classnames";
import { useRef, type JSX } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiFillEye } from "../../../react-icons/ai";
import { BsArchive, BsCheckLg, BsFillMoonFill, BsFillSunFill, BsThreeDots, BsThreeDotsVertical } from "../../../react-icons/bs";
import { FaDownload, FaPowerOff } from "../../../react-icons/fa";
import { HiOutlineTrash } from "../../../react-icons/hi2";
import { IoChevronDownOutline } from "../../../react-icons/io5";
import { MdModeEditOutline } from "../../../react-icons/md";

type ItemType = {
  icon?: "check" | "edit" | "trash" | "archive" | "disconnect" | "sun" | "moon" | "download" | "view" | JSX.Element;
  legend: string;
  cb: (e?: React.MouseEvent<HTMLElement>) => void;
};

type Props = {
  icon?: "vertical" | "horizontal" | "arrow";
  items: ItemType[];
  size?: number;
  id?: string;
  className?: string;
};

declare const window: any;

export function MenuListButton({ icon = "vertical", items, size = 18, className, id }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const listRef: any = useRef(null);
  const buttonRef: any = useRef(null);

  const clickOutside = (e: any) => {
    e.stopPropagation();
    if (!buttonRef.current?.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen == true) {
      window.addEventListener("click", clickOutside, false);
    } else {
      window.removeEventListener("click", clickOutside, false);
    }
    return () => {
      window.removeEventListener("click", clickOutside, false);
    };
  }, [isOpen]);

  return (
    <div
      id={id}
      className={classNames("menu-list-container clickable", className)}
      onClick={(e) => {
        e.stopPropagation();
        setIsOpen((prev) => !prev);
      }}
      ref={listRef}
    >
      <div className={isOpen === false ? "button clickable" : "button"} ref={buttonRef}>
        {icon == "vertical" && <BsThreeDotsVertical color="var(--color-gray9)" size={size} />}
        {icon == "horizontal" && <BsThreeDots color="var(--color-gray9)" size={size} />}
        {icon == "arrow" && <IoChevronDownOutline color="var(--color-gray9)" size={size} />}
      </div>

      <ul className={classNames("menu-list", { hidden: !isOpen })} style={{ zIndex: "80" }}>
        {items.map((item, key) => {
          return (
            <li
              className="clickable"
              key={key}
              onClick={(e) => {
                e.stopPropagation();
                item.cb(e);
                setIsOpen(false);
              }}
            >
              {item.icon == "check" && <BsCheckLg size={size} />}
              {item.icon == "edit" && <MdModeEditOutline size={size} />}
              {item.icon == "trash" && <HiOutlineTrash size={size} strokeWidth={2} />}
              {item.icon == "archive" && <BsArchive size={size} />}
              {item.icon == "disconnect" && <FaPowerOff size={size} />}
              {item.icon == "sun" && <BsFillSunFill size={size} />}
              {item.icon == "moon" && <BsFillMoonFill size={size} />}
              {item.icon == "download" && <FaDownload size={size} />}
              {item.icon == "view" && <AiFillEye size={size} />}
              {typeof item.icon !== "string" && item.icon}

              {item.legend}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
