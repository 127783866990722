import React from "react";
import {
  BsCheckCircleFill,
  BsCheckLg,
  BsFillArchiveFill,
} from "../../../react-icons/bs";
import { IoCloseCircle } from "../../../react-icons/io5";
import { MdModeEditOutline } from "../../../react-icons/md";
import { TbArrowBigRightFilled, TbListCheck } from "../../../react-icons/tb";
import { StatusType } from "../../badges";

type StatusValue = StatusType["value"];

type Props = {
  icon: StatusValue;
  iconSize?: number;
};

export function QuestionStatusIcon({ icon, iconSize = 16 }: Props) {

  return (
    <>
      {icon == "archived" && <BsFillArchiveFill size={iconSize - 3} />}
      {icon == "created" && <MdModeEditOutline size={iconSize} />}
      {icon == "validated" && <BsCheckLg size={iconSize} />}
      {icon == "send_to_client" && <TbArrowBigRightFilled size={iconSize} />}
      {icon == "answered_by_client" && <BsCheckLg size={iconSize} />}
      {icon == "to_process" && <TbListCheck size={iconSize} />}
      {icon == "denied" && <IoCloseCircle size={iconSize} />}
      {icon == "accepted" && <BsCheckCircleFill size={iconSize} />}
    </>
  );
}
