import classNames from "classnames";
import React from "react";

type Props = {
  children: any | any[];
  position?: "left" | "center" | "right" | "auto";
  espacement?: number;
  className?: string;
};

export default function BtnContainerMobile({ children, position = "auto", espacement = 7, className }: Props) {
  return (
    <div
      className={classNames(className, "btn-container")}
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        gap: espacement + "px",
        marginLeft: position === "left" || position === "auto" ? 0 : "auto",
        marginRight: position === "right" || position === "auto" ? 0 : "auto",
        width: position === "auto" ? "100%" : "",
      }}
    >
      {children}
    </div>
  );
}
