import React from "react";
import { BsWindows } from "../../../react-icons/bs";
import { Tooltip } from "../../infos";
import { SiMacos } from "../../../react-icons/si";
import { FaAppStore, FaChrome, FaLinux } from "../../../react-icons/fa";
import { TiVendorAndroid } from "../../../react-icons/ti";

type Props = {
  os: "windows" | "mac" | "linux" | "ios" | "chrome" | "android" | string;
  size?: number;
};

export function OsIcon({ os, size = 25 }: Props) {
  return (
    <Tooltip legend={os}>
      {os?.toLowerCase().includes("windows") && (
        <BsWindows color="var(--color-main)" size={size} />
      )}
      {os?.toLowerCase().includes("mac") && (
        <SiMacos color="var(--color-main)" size={size} />
      )}
      {os?.toLowerCase().includes("linux") && (
        <FaLinux color="var(--color-main)" size={size} />
      )}
      {os?.toLowerCase().includes("ios") && (
        <FaAppStore color="var(--color-main)" size={size} />
      )}
      {os?.toLowerCase().includes("chrome") && (
        <FaChrome color="var(--color-main)" size={size} />
      )}
      {os?.toLowerCase().includes("android") && (
        <TiVendorAndroid color="var(--color-main)" size={size} />
      )}
    </Tooltip>
  );
}
