import React from "react";
import { GrArchive } from "../../../react-icons/gr";
import { IoDocumentTextOutline } from "../../../react-icons/io5";
import {
  PiFileCsv,
  PiFileJpgLight,
  PiFilePdf,
  PiFilePngDuotone,
  PiFileZip,
} from "../../../react-icons/pi";
import { TbFileTypeDocx } from "../../../react-icons/tb";

type Props = {
  size?: number;
  color?: string;
  type: "image/png" | "application/pdf" | "application/x-zip-compressed" | "application/vnd.rar" | "image/jpeg" | "image/jpg" | "text/csv" | "application/msword" | "text/plain";
};

export function FileTypeIcon({ size = 25, color = "main", type }: Props) {
  return (
    <>
      {type === "image/png" && (
        <PiFilePngDuotone size={size} color={"var(--color-" + color + ")"} />
      )}
      {type === "application/pdf" && (
        <PiFilePdf size={size} color={"var(--color-" + color + ")"} />
      )}
      {type === "application/x-zip-compressed" && (
        <PiFileZip size={size} color={"var(--color-" + color + ")"} />
      )}
      {type === "application/vnd.rar" && (
        <GrArchive size={size} color={"var(--color-" + color + ")"} />
      )}
      {(type === "image/jpeg" || type === "image/jpg") && (
        <PiFileJpgLight size={size} color={"var(--color-" + color + ")"} />
      )}
      {type === "text/csv" && (
        <PiFileCsv size={size} color={"var(--color-" + color + ")"} />
      )}
      {type === "application/msword" && (
        <TbFileTypeDocx size={size} color={"var(--color-" + color + ")"} />
      )}
      {type === "text/plain" && (
        <IoDocumentTextOutline
          size={size}
          color={"var(--color-" + color + ")"}
        />
      )}
    </>
  );
}
